import React from 'react'
// import "./footer.css"

import style from './footer.module.css';
const footer = () => {
  return (

    <footer className={style.footer_container} >
     <div className={style.footer_text_container}>
      <p>Copyrights 2024 © SHRI GANESH  TEMPLE TRUST.<br /> All rights reserved</p>
     </div>
    </footer>
   
  )
}

export default footer
