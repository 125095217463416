import './App.css';
import Hero from './comonents/header/Hero';
// import Nav from './comonents/nav/nav';
import Nav from './comonents/nav_2/Nav2';

import Feature from './comonents/feature/feature';
import Footer from './comonents/footer/footer';
import History from './comonents/history/history';
import Gallary from './comonents/gallary/gallary';
import Contact from './comonents/contact/contact';
import What_we_do from './comonents/what_we_do/what_we_do';
import About from './comonents/about/about';
import Social from "./comonents/social/social";
import Featured_top_section from "./comonents/featured_top_section/featured_top_section";
import Service from "./comonents/service/service";
function App() {
  return (
    <div className="App">
      <Nav id="nav"/>
      <Hero id="hero" />
      {/* <Featured_top_section id="featured_top_section" /> */}
      <About id="about" />
      <Feature  id="feature"/>
      {/* <History /> */}
      {/* <What_we_do /> */}
      <Gallary id="gallary" />
      {/* <Service /> */}
      <Contact id="contact" />
      <Social  id="social"/>
      <Footer id="footer" />
    </div>
  );
}

export default App;
