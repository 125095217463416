import React from 'react'
import "./feature.css"
import Vision_img from "../../image/vision_img.jpg"
const feature = () => {
  return (
    <div  className='vision_container'>
   <div className="vision_left_container">
    <div className="vision_header_container">
      <p>Our Core Vision</p>
    </div>
    <div className="vision_text_container">
    Our core vision is to create a welcoming, spiritually uplifting space where individuals from all backgrounds can connect deeply with divine wisdom and peace. We aim to inspire positive change, fostering a compassionate and inclusive community rooted in the teachings of Lord Ganesha. By embracing tradition while adapting to modern needs, we strive to preserve cultural heritage and promote values of service, empathy, and self-growth. Through worship, education, and community outreach, our vision is to remove obstacles in the lives of individuals and guide them toward harmony, personal development, and a more connected, spiritually fulfilling life.
    </div>
   </div>
   <div className="vision_right_container">
    <img src={Vision_img}  className='vision_img'/>
   </div>
    </div>
  )
}

export default feature
