import React from "react";
import "./social.css";
import { SlSocialFacebook } from "react-icons/sl";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import { SlSocialYoutube } from "react-icons/sl";

const social = () => {
  return (
    <div className="social_container" >
      <SocialIcons icon={<SlSocialFacebook  size={20} />} text="Facebook" />
      <SocialIcons icon={<FaXTwitter   size={20}/>} text="Twitter" />
      <SocialIcons icon={<IoLogoInstagram size={20} />} text="Instagram" />
      <SocialIcons icon={<SlSocialYoutube size={20} />} text="Youtube" />
    </div>
  );
};
const SocialIcons = ({ icon, text }) => (
    <div className="social_info_container">
      <div className="social_icon_container">{icon}</div>
      <div className="social_text_container">{text}</div>
    </div>
);
export default social;
