import React from "react";
import "./gallary.css";
import Ganesh_1 from "../../image/ganesh_1.jpeg";
import Ganesh_2 from "../../image/ganesh_2.jpeg";
import Ganesh_3 from "../../image/ganesh_3.jpeg";
import Ganesh_4 from "../../image/ganesh_4.jpeg";
import Ganesh_5 from "../../image/ganesh_5.jpeg";
import Ganesh_6 from "../../image/ganesh_6.jpeg";
import Ganesh_7 from "../../image/ganesh_7.jpeg";
import Ganesh_8 from "../../image/ganesh_8.jpeg";
const gallary = () => {
  return (
    <div className="gallary_container" id="gallery" >
      <div className="gallary_header">
        <p>Photo Gallery</p>
      </div>
      <div className="gallary_img_container">
        <img src={Ganesh_1} className="img_ganesh" />
        <img src={Ganesh_2} className="img_ganesh" />
        <img src={Ganesh_3} className="img_ganesh" />
        <img src={Ganesh_4} className="img_ganesh" />
        <img src={Ganesh_5} className="img_ganesh" />
        <img src={Ganesh_6} className="img_ganesh" />
        <img src={Ganesh_7} className="img_ganesh" />
        <img src={Ganesh_8} className="img_ganesh" />
        <img src={Ganesh_1} className="img_ganesh" />

     </div>
    </div>
  );
};

export default gallary;
