import React from "react";
import "./contact.css";
import { CiLocationOn } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { PiTelegramLogo } from "react-icons/pi";

const CONTACT_LOGO_SIZE=30;

const contact = () => {
  return (
    <div className="contact_container" id="contact" >
      <ContactInfo
        icon={<CiLocationOn size={CONTACT_LOGO_SIZE} className="icon" />}
        header="Address"
        text="Bata Mangala, Puri,Odisha"
      />
      <ContactInfo
        icon={<IoCallOutline size={CONTACT_LOGO_SIZE} className="icon" />}
        header="Phone"
        text=""
      />
      <ContactInfo
        icon={<PiTelegramLogo size={CONTACT_LOGO_SIZE} className="icon" />}
        header="Mail"
        text="demo@mail.com<br /> mail.democo.info"
      />
    </div>
  );
};
const ContactInfo = ({ icon, header, text }) => (
  <div className="contact_info_container">
    <div className="contact_icon_container">{icon}</div>
    <div className="contact_info_header_container">
      <h1 className="contact_info_header_content">{header}</h1>
    </div>
    <div
      className="contact_info_text_container"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </div>
);
export default contact;
