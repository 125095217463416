import React from 'react'
import "./Hero.css"
const Hero = () => {
  return (
    <div className="hero-section">
      <video className="hero-video" autoPlay loop muted>
        <source src="/Video/Ganesh_vdo.mp4" type="video/mp4" />
      </video>
    </div>
  );
}


export default Hero
