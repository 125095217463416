import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState } from "react";
import style from "./Nav2.module.css";

const NAV_HEIGHT = 60; // Adjust this to your navbar height

function Nav2() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (sectionId === "#home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (section) {
      const yOffset = -NAV_HEIGHT; // Offset based on navbar height
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
      handleClose(); // Close the navbar after scrolling
    }
  };

  return (
    <Navbar
      expand="lg"
      className={`bg-body-tertiary ${style.navbar}`}
      sticky="top"
      expanded={expanded} // Set expanded based on state
      onToggle={handleToggle}
    >
      <Container className={style.navMenu}>
        <Navbar.Brand
          onClick={() => {
            handleClose();
            scrollToSection("#home");
          }}
        >
          React-Bootstrap
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={style.navbarCollapse}>
          <Nav className={`ms-auto ${style.navButton_container}`}>
            <Nav.Link
              className={style.navButton}
              onClick={() => {
                handleClose();
                scrollToSection("#home");
              }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={style.navButton}
              onClick={() => {
                handleClose();
                scrollToSection("#about");
              }}
            >
              About
            </Nav.Link>
            <Nav.Link
              className={style.navButton}
              onClick={() => {
                handleClose();
                scrollToSection("#contact");
              }}
            >
              Contact
            </Nav.Link>
            <Nav.Link
              className={style.navButton}
              onClick={() => {
                handleClose();
                scrollToSection("#gallery");
              }}
            >
              Gallery
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Nav2;
