import React from 'react'
import "./about.css"
import About_img from "../../image/ganesh_4.jpeg"

const about = () => {
  return (
    <div className='about_container' id="about" >
 <div className="about_left_container">
  <img src={About_img} className='about_img' />
 </div>


 <div className="about_right_container">
<div className="about_header">
  Ganesh Temple
</div>
<div className="about_text">
<div className="about_text_1">
Welcome to [Temple Name], a revered sanctuary dedicated to Lord Ganesha in [Location]. Established in [Year], the temple is a spiritual haven where devotees seek wisdom, prosperity, and blessings. Known for its stunning [Specify Style] architecture and intricately carved sanctum, the temple offers a peaceful ambiance for prayer and reflection. The main shrine, hosting a beautiful idol of Lord Ganesha, is the heart of daily rituals and aarti ceremonies. Vibrant celebrations mark Ganesh Chaturthi, attracting thousands. Open daily, [Temple Name] provides a place for community worship, spiritual events, and charitable activities, inviting all to experience divine grace.
</div>

{/* <div className="about_text_1">
  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione suscipit optio, provident cumque ipsum ex, quisquam, esse odio eveniet eius officia reiciendis exercitationem sint sunt deleniti? Dolorum explicabo porro, saepe temporibus voluptate ab odit laudantium id voluptas a deleniti. Maxime!
</div> */}
</div>
 </div>


    </div>
  )
}

export default about
